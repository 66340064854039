import * as React from 'react';
import { connect } from 'react-krax';
import { getControlText, getLanguageID } from '../shared/functions';
import { BrowserRouter, Link } from 'react-router-dom';
import moment from 'moment';
import { getSystemText } from '../store/flight';

class bannerList extends React.Component {
  render() {
    const officersIds = [];

    let fto = '';
    let freturn = '';
    let foperator = '';

    if (true) {
      fto = getControlText('895', []) ? getControlText('58', []).Text : '';
      freturn = getControlText('896', []) ? getControlText('72', []).Text : '';
      foperator = getControlText('908', []) ? getControlText('908', []).Text : '';
    }

    if (this.props && this.props.dataSource) {
      this.props.dataSource.forEach(function(row) {
        officersIds.push(
          // <Link rel="nofollow" to={{
          //     pathname: '/vliegtickets/turkije/' + row.DepartureText.toLowerCase().replace(' ','') + '/' + row.ArrivalText.toLowerCase().replace(' ',''),
          //     state:{
          //         SearchType: '1',
          //         FlightDate:row.FlightDatemini2,
          //         RetFlightDate:moment(row.FlightDatemini).format('YYYY-MM-DD'),
          //         FromAirport:row.DepartureID,
          //         ToAirport: row.ArrivalID,
          //         oneway:'1',
          //         Adult:1,
          //         Child:0,
          //         Baby:0,
          //         OnlyDirect:1,
          //         From:row.DepartureText,
          //         To:row.ArrivalText
          //     }
          // } }   >
          <Link
            rel="nofollow"
            to={{
              pathname: '/',
              state: {
                SearchType: '1',
                FlightDate: row.FlightDatemini2,
                RetFlightDate: moment(row.FlightDatemini).format('YYYY-MM-DD'),
                FromAirport: row.DepartureID,
                ToAirport: row.ArrivalID,
                oneway: '1',
                Adult: 1,
                Child: 0,
                Baby: 0,
                OnlyDirect: 1,
                From: row.DepartureText,
                To: row.ArrivalText
              }
            }}>
            <div className="row _pl-10">
              <div className="col-xs-8">
                <div className="blfd_caption">
                  <span className="banner-caption-dark">
                    <i className="fa fa-plane" /> {row.DepartureText}
                  </span>{' '}
                  <i className="fa fa-plane return_icon" /> {row.ArrivalText}{' '}
                </div>
                <div className="blfd_content">
                  {fto} : {row.DepartureTimeShort} • {freturn} : {row.ArrivalTimeShort}
                </div>
                <div className="blfd_airline">{row.Airline}</div>
              </div>
              <div className="col-xs-4 _pt--30 ">
                <div className="blfd_banner_price">€{row.minprice}</div>
                <div className="blfd_banner_tarih">{row.FlightDate.replace('00:00:00', '')}</div>
              </div>
              <div className="col-md-12">
                {' '}
                <hr className="ozel_row" />
              </div>
            </div>
          </Link>
        );
      });
    }
    return (
      <div className="theme-search-area  _bg-p _br-4 _mb-20 _bsh theme-search-area-vert theme-search-area-white">
        <div className="bannerlist_baslik">
          <div className="row row-col-gap">
            <div className="col-md-12 text-center">
              { <a href={this.props.link}  className="_c-w"> {this.props.home ?  <h2 className="h2_pri">{this.props.caption}</h2> : <h3>{this.props.caption}</h3>}</a> }
               
            </div>
          </div>
        </div>
        <div className="bannerlist_content">
          {this.props.waether ? (
            <div className="weather_list _mb-10">
              <div className="row">{this.props.waether}</div>
            </div>
          ) : (
            ''
          )}
          {officersIds}
        </div>
      </div>
    );
   // return(null);
  }
}

export default connect(state => state)(bannerList);
