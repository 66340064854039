import * as React from 'react';
import SearchFlight2 from '../../routes/inc/SearchFlightBar';
import { connect } from 'react-redux';
import Banner2 from '../inc/banner';
import BannerList from '../inc/bannerlist';
import { getBanners, getBlog, getSystemText } from '../store/flight';
import { getControlText, getLanguageID, getUserID } from '../shared/functions';
import Widget from '../inc/widget';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import TagManager from 'react-gtm-module';
import Cookies from 'js-cookie';

import { isMobile } from 'react-device-detect';
import { frontloadConnect } from 'react-frontload';
import { bindActionCreators } from 'redux';
import { setBlogList3, setSystemText } from '../../../modules/general';
import Page from '../../components/page';
import HomeBanner from '../inc/HomeBanner';
import { askForPermissioToReceiveNotifications } from '../../push-notification';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const tagManagerArgs = {
  dataLayer: {
    event: 'HOME'
  },
  dataLayerName: 'dataLayer'
};

async function frontload(props) {
  let promise = new Promise((resolve, reject) => {
    getSystemText('1', getLanguageID()).then(val => {
      getBlog('10', getLanguageID()).then(val => {
        props.setBlogList3(val.data.d);
        resolve('ok');
      });
    });
  });

  let result = await promise;
}

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      slider_string: '',
      username: Cookies.get('userName'),
      visible: false,
      width: '',
      height: ''
    };

    this.chd = React.createRef();
  }

  openModal() {
    this.setState({
      visible: true
    });
  }

  closeModal() {
    this.setState({
      visible: false
    });
  }

  showDelay(slidenumber, photolist) {
    const min = 1;
    const max = photolist.length;
    const rand = Math.floor(min + Math.random() * (max - min)) - 1;

    if (slidenumber === '0' || slidenumber === '') {
      this.setState({
        slider_string: photolist[rand]
      });
    }
  }

  async componentDidMount() {

    /*withReactContent(Swal).fire({
      icon: "info",
      html: `<p><strong>Op dit moment hebben wij een onderhoud op onze website.</strong></p>
      <p>Hierdoor kan het voorkomen dat niet alle functionaliteiten naar behoren werken.</p>
      <p>Als u ondersteuning wenst kunt u contact opnemen met onze callcenter op het nummer <a href="tel:+31758200115">075-8200115</a>.</p>
      <p>Wij danken u alvast voor uw begrip.</p>`,
      confirmButtonText: "OK"
    });*/

    if (isMobile) {
      this.setState({
        width: '100%',
        height: '90%'
      });
    } else {
      this.setState({
        width: '50%',
        height: '70%'
      });
    }

    Cookies.set('userName', 'mas');

    TagManager.dataLayer(tagManagerArgs);

    var currentDate = moment('2015-10-30', 'YYYY-MM-DD');
    var futureMonth = currentDate.add(1, 'M');
    var futureMonthEnd = moment(futureMonth).endOf('month');

    if (currentDate.date() != futureMonth.date() && futureMonth.isSame(futureMonthEnd.format('YYYY-MM-DD'))) {
      futureMonth = futureMonth.add(1, 'd');
    }

    ReactGA.pageview(this.props.location.pathname + this.props.location.search);
    var timeout = 1;
    if (isMobile) timeout = 3000;

    try {
      const response = await askForPermissioToReceiveNotifications();
    } catch (error) {
      console.error(error);
    }
    if (typeof window !== 'undefined') window.postMessage('Sending data from WebView');
  }

  loadBanners() {
    getBanners('banners1', '5', '3', '0', '14', getUserID(), '').then(val => {
      this.setState({ banners1: val.data.d });
    });

    getBanners('banners2', '5', '3', '0', '28', getUserID(), '').then(val => {
      this.setState({ banners2: val.data.d });
    });

    getBanners('banners3', '5', '3', '0', '12', getUserID(), '').then(val => {
      this.setState({ banners3: val.data.d });
    });

    getBanners('banners4', '3', '3', '19', '12', getUserID(), '').then(val => {
      this.setState({ banners4: val.data.d });
    });

    getBanners('banners5', '5', '3', '0', '141', getUserID(), '').then(val => {
      this.setState({ banners5: val.data.d });
    });

    getBanners('banners6', '5', '3', '0', '18', getUserID(), '').then(val => {
      this.setState({ banners6: val.data.d });
    });

    getBanners('banners7', '5', '3', '0', '53', getUserID(), '').then(val => {
      this.setState({ banners7: val.data.d });
    });
  }

  render() {
    let title = '',
      description = '',
      titleh1 = '',
      blogh1 = '';
    if (this.props && this.props.general) {
      title = getControlText('951', this.props.general.systemText).Text;
      titleh1 = getControlText('1077', this.props.general.systemText).Text;
      blogh1 = getControlText('1078', this.props.general.systemText).Text;
      description = getControlText('982', this.props.general.systemText).Text;
    }

    var banner1 = [];
    if (this.state && this.state.banners1) banner1 = this.state.banners1;

    var banner2 = [];
    if (this.state && this.state.banners2) banner2 = this.state.banners2;

    var banner3 = [];
    if (this.state && this.state.banners3) banner3 = this.state.banners3;

    var banner4 = [];
    if (this.state && this.state.banners4) banner4 = this.state.banners4;

    var banner5 = [];
    if (this.state && this.state.banners5) banner5 = this.state.banners5;

    var banner6 = [];
    if (this.state && this.state.banners6) banner6 = this.state.banners6;

    var banner7 = [];
    if (this.state && this.state.banners7) banner7 = this.state.banners7;

    const blogIds3 = [];
    var blogs3 = [];

    if (this.props && this.props.general && this.props.general.blogList3) {
      blogs3 = this.props.general.blogList3;
    }

    if (blogs3 && !isMobile) {
      let i = 1;
      blogs3.forEach(function(row) {
        if (i <= 6) {
          blogIds3.push(
            <div className="col-md-4  ">
              <a href={row.sitemapUrl}>
                <div className="banner  blog_detail_3 ">
                  <div className="banner-bg">
                    <img alt={row.Title} src={row.images[0] && '/Content/Dynamic/Small/' + row.images[0].FileName} />
                  </div>
                  <div className="banner-caption banner-caption-bottom">
                    <div className="blog_title">
                      {' '}
                      <h3> {row.Title}</h3>
                      <p>{row.Keywords}</p>{' '}
                    </div>
                  </div>
                </div>
              </a>
            </div>
          );
        }
        i = i + 1;
      });
    }

    return (
      <div>
        <Helmet>
          <meta name="ahrefs-site-verification" content="cbb8945c19751d46eb1a478a3d1b68c34872331ca5e8b46226c163fc545b3e0d" />
        </Helmet>
        <Page id="profile" title={title} description={description} />

        <div className="theme-hero-area theme-hero-area-primary ">
          <div className="theme-hero-area-bg-wrap">
            <HomeBanner className="theme-hero-area-bg" />
            <div className="theme-hero-area-mask theme-hero-area-grad-mask-light" />
            <div className="theme-hero-area-inner-shadow" />
          </div>
          <div className="theme-hero-area-body  ">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="_pt-60 _pt-mob-10 _pb-mob-10  ">
                    <div className="theme-search-area-tabs">
                      <div className="theme-search-area-tabs-header _c-w _ta-c" />

                      <SearchFlight2 OnlyDirect={true} />
                    </div>
                  </div>

                  <div className="widget_sag_alt ">
                    <Widget />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="default-page-title">
          <h1>
            <a href="https://www.triptime.nl/goedkope-vliegtickets/turkije">Goedkope vliegtickets naar Turkije</a> |{' '}
            <a href="https://www.triptime.nl/vliegtickets/turkije/istanbul">Istanbul</a> |{' '}
            <a href="https://www.triptime.nl/vliegtickets/turkije/izmir">Izmir</a> |{' '}
            <a href="https://www.triptime.nl/vliegtickets/turkije/antalya">Antalya</a> -{' '}
            <a href="https://www.triptime.nl/maatschappij/sunexpress-airlines/wie-is-sunexpress-airlines">Met </a>
            <a href="https://www.triptime.nl/maatschappij/sunexpress-airlines/wie-is-sunexpress-airlines">SunExpress</a>
            <a href="https://www.triptime.nl/maatschappij/sunexpress-airlines/wie-is-sunexpress-airlines"> </a>|{' '}
            <a href="https://www.triptime.nl/maatschappij/pegasus-airlines/wie-is-pegasus-airlines">Pegasus</a> |{' '}
            <a href="https://www.triptime.nl/maatschappij/corendon/wie-is-corendon">Corendon</a> |{' '}
            <a href="https://www.triptime.nl/maatschappij/turkish-airlines/wie-is-turkish-airlines">Turkish Airlines</a>
          </h1>
        </div>

        <Banner2 dataSource={banner4} />

        <div className="theme-page-section ">
          <div className="container">
            <div className="theme-search-results _mb-10">
              <div className="row row-col-gap">
                <div className="col-md-4 ">
                  <BannerList caption="Lastminute Istanbul" link="/last-minute/turkije/istanbul" top={5} dataSource={banner1} home={true} />
                </div>

                <div className="col-md-4 ">
                  <BannerList caption="Lastminute Izmir" link="/last-minute/turkije/izmir" top={5} dataSource={banner2} home={true} />
                </div>

                <div className="col-md-4 ">
                  <BannerList caption="Lastminute Antalya" link="/last-minute/turkije/antalya" top={5} dataSource={banner3} home={true} />
                </div>
              </div>

              <div className="row row-col-gap">
                <div className="col-md-4  ">
                  <BannerList caption="Lastminute Alanya" link="/last-minute/turkije/alanya" top={5} dataSource={banner5} home={true} />
                </div>

                <div className="col-md-4 ">
                  <BannerList caption="Lastminute Bodrum" link="/last-minute/turkije/bodrum" top={5} dataSource={banner6} home={true} />
                </div>

                <div className="col-md-4 ">
                  <BannerList caption="Lastminute Kayseri" link="/last-minute/turkije/kayseri" top={5} dataSource={banner7} home={true} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="theme-page-section  bg_alternate _mob-h ">
          <div className="container blog_title ">
            <h2>
              Top 3 reisverhalen <a href="/vliegtickets-turkije">vliegtickets Turkije</a>
            </h2>

            <div className="theme-search-results _mb-10">
              <div className="row  _ph-50 _pv-10 ">{blogIds3}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state };
}

const mapDispatchToProps = dispatch => bindActionCreators({ setSystemText, setBlogList3 }, dispatch);

var l = connect();

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  frontloadConnect(frontload, {
    onMount: true,
    onUpdate: false
  })(Index)
);
