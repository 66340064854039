import * as React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-krax';
class Widget extends React.Component {
  render() {
    return (
      <div className="_p-5  _bg-p-grad _br-10 _widget">
        <div className="bg-grad-w">
          {' '}
          <iframe scrolling="no" frameBorder="0" height="115" width="196" src="https://beoordelingen.feedbackcompany.nl/widget/23648.html" />
        </div>
      </div>
    );
  }
}

export default connect(state => state)(withRouter(Widget));
