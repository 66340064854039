import * as React from 'react';
import { isMobile } from 'react-device-detect';

import { withRouter } from 'react-router';
class HomeBanner extends React.Component {
  constructor(props) {
    super(props);
    {
      this.state = {
        isActive: this.props.isActive,
        btn_select: '',
        btn_unselect: '',
        ressumMode: this.props.ressumMode ? this.props.ressumMode : false
      };
    }
  }

  state = { src: null };

  showDelay(slidenumber, photolist) {
    const min = 1;
    const max = photolist.length;
    const rand = Math.floor(min + Math.random() * (max - min)) - 1;

    if (slidenumber === '0' || slidenumber === '') {
      this.setState({
        slider_string: photolist[rand]
      });
    }
  }

  componentDidMount() {
    let fotolist = [
      '/img/bg/genel_1.jpg',
      '/img/bg/genel_2.jpg',
      '/img/bg/genel_3.jpg',
      '/img/bg/genel_4.jpg',
      '/img/bg/genel_5.jpg',
      '/img/bg/genel_6.jpg',
      '/img/bg/genel_7.jpg',
      '/img/bg/genel_8.jpg',
      '/img/bg/genel_9.jpg',
      '/img/bg/genel_10.jpg',
      '/img/bg/genel_11.jpg',
      '/img/bg/genel_12.jpg',
      '/img/bg/genel_13.jpg',
      '/img/bg/genel_14.jpg',
      '/img/bg/genel_15.jpg',
      '/img/bg/genel_16.jpg',
      '/img/bg/genel_17.jpg',
      '/img/bg/genel_18.jpg',
      '/img/bg/genel_19.jpg',
      '/img/bg/genel_20.jpg',
      '/img/bg/genel_21.jpg',
      '/img/bg/genel_22.jpg',
      '/img/bg/genel_23.jpg',
      '/img/bg/genel_24.jpg',
      '/img/bg/genel_25.jpg',
      '/img/bg/genel_26.jpg',
      '/img/bg/genel_27.jpg',
      '/img/bg/genel_28.jpg',
      '/img/bg/genel_29.jpg',
      '/img/bg/genel_30.jpg',
      '/img/bg/genel_31.jpg',
      '/img/bg/genel_32.jpg',
      '/img/bg/genel_33.jpg',
      '/img/bg/genel_34.jpg',
      '/img/bg/genel_35.jpg',
      '/img/bg/genel_36.jpg',
      '/img/bg/genel_37.jpg',
      '/img/bg/genel_38.jpg',
      '/img/bg/genel_39.jpg',
      '/img/bg/genel_40.jpg',
      '/img/bg/genel_41.jpg',
      '/img/bg/genel_42.jpg',
      '/img/bg/genel_43.jpg',
      '/img/bg/genel_44.jpg',
      '/img/bg/genel_45.jpg',
      '/img/bg/genel_46.jpg',
      '/img/bg/genel_47.jpg',
      '/img/bg/genel_48.jpg',
      '/img/bg/genel_49.jpg',
      '/img/bg/genel_50.jpg',
      '/img/bg/genel_51.jpg',
      '/img/bg/genel_52.jpg',
      '/img/bg/genel_53.jpg',
      '/img/bg/genel_54.jpg',
      '/img/bg/genel_55.jpg',
      '/img/bg/genel_56.jpg',
      '/img/bg/genel_57.jpg',
      '/img/bg/genel_58.jpg',
      '/img/bg/genel_59.jpg',
      '/img/bg/genel_60.jpg',
      '/img/bg/genel_61.jpg',
      '/img/bg/genel_62.jpg',
      '/img/bg/genel_63.jpg'
    ];

    var timeout = 1;
    if (isMobile) timeout = 4000;
    setTimeout(() => this.showDelay('0', fotolist), timeout);

    const imageLoader = new Image();
    imageLoader.src = this.state.slider_string;

    imageLoader.onload = () => {
      this.setState({ src: this.state.slider_string });
    };
  }

  render() {
    return <div {...this.props} style={{ backgroundImage: `url(${this.state.slider_string || this.props.placeholder})` }} />;
  }
}

export default HomeBanner;
